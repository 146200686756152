
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Lib/entities/publicPresenter/AssetQuotationRequest';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import Checkbox from 'Control/Checkbox.vue';
import Button from 'Control/Button.vue';

type Card = {
    subHeader: string;
    name: string;
    benefits?: string[];
    price: string;
    priceNumber: number;
    requestsNumber?: number;
    pricePeriod?: string;
    info: string[];
    summaryHeader: string;
    summaryBilling?: string;
    summarySubHeader?: string;
    checked: boolean;
    quantity: number;
};

type Data = {
    packages: {
        header: string;
        isApi?: boolean;
        cards: Card[];
    }[];
    fiatAssetSymbols: string[];
    activeFiatAssetIndex: number;
    quotationRate: number;
    agreeTermsAndConditions: boolean;
};

type Methods = {
    toggleCardChecked: (packageIndex: number, cardIndex: number) => void;
    incrementCardQuantity: (packageIndex: number, cardIndex: number) => void;
    decrementCardQuantity: (packageIndex: number, cardIndex: number) => void;
    getCardCount: (card: Card) => number;
    getCardSummaryPrice: (card: Card) => number;
};

type Computed = {
    quotationAssetSymbol: string;
    availableBalance: number;
    summaryCards: Card[];
    quotationAssetPrecision: number;
    totalPrice: number;
    isAnyPackagesSelected: boolean;
};

export default Vue.extend<Data, Methods, Computed>({
    components: { Button, Checkbox, AssetsBlockchainsDropdown, Icon },
    data() {
        return {
            packages: [
                {
                    header: 'owned Accounts',
                    cards: [
                        {
                            subHeader: '1 Additional Owned Account',
                            name: 'Monthly Subscription',
                            price: '$ 9.90',
                            priceNumber: 9.9,
                            pricePeriod: '/Month',
                            info: ['Billed Monthly'],
                            summaryHeader: 'Additional Owned Account',
                            summarySubHeader: 'Monthly Subscription',
                            summaryBilling: 'Billed monthly',
                            checked: false,
                            quantity: 1,
                        },
                        {
                            subHeader: '1 Additional Owned Account',
                            name: 'Yearly Subscription',
                            benefits: ['You Save $20 per Year 17% Benifit', 'Compared to Monthly Plan'],
                            price: '$ 98.80',
                            priceNumber: 98.8,
                            pricePeriod: '/Year',
                            info: ['Billed Yearly', '$ 8.24/Month'],
                            summaryHeader: 'Additional Owned Account',
                            summarySubHeader: 'Yearly Subscription',
                            summaryBilling: 'Billed yearly',
                            checked: false,
                            quantity: 1,
                        },
                    ],
                },
                {
                    header: 'managed Accounts',
                    cards: [
                        {
                            subHeader: '1 Additional Managed Account',
                            name: 'Monthly Subscription',
                            price: '$ 9.90',
                            priceNumber: 9.9,
                            pricePeriod: '/Month',
                            info: ['Billed Monthly'],
                            summaryHeader: 'Additional Managed Account',
                            summarySubHeader: 'Monthly Subscription',
                            summaryBilling: 'Billed monthly',
                            checked: false,
                            quantity: 1,
                        },
                        {
                            subHeader: '1 Additional Managed Account',
                            name: 'Yearly Subscription',
                            benefits: ['You Save $20 per Year 17% Benifit', 'Compared to Monthly Plan'],
                            price: '$ 98.80',
                            priceNumber: 98.8,
                            pricePeriod: '/Year',
                            info: ['Billed Yearly', '$ 8.24/Month'],
                            summaryHeader: 'Additional Managed Account',
                            summarySubHeader: 'Yearly Subscription',
                            summaryBilling: 'Billed yearly',
                            checked: false,
                            quantity: 1,
                        },
                    ],
                },
                {
                    header: 'institutional groups',
                    cards: [
                        {
                            subHeader: '1 Additional Institutional Group',
                            name: 'Monthly Subscription',
                            price: '$ 9.90',
                            priceNumber: 9.9,
                            pricePeriod: '/Month',
                            info: ['Billed Monthly'],
                            summaryHeader: 'Additional Institutional Group',
                            summarySubHeader: 'Monthly Subscription',
                            summaryBilling: 'Billed monthly',
                            checked: false,
                            quantity: 1,
                        },
                        {
                            subHeader: '1 Additional Institutional Group',
                            name: 'Yearly Subscription',
                            benefits: ['You Save $20 per Year 17% Benifit', 'Compared to Monthly Plan'],
                            price: '$ 98.80',
                            priceNumber: 98.8,
                            pricePeriod: '/Year',
                            info: ['Billed Yearly', '$ 8.24/Month'],
                            summaryHeader: 'Additional Institutional Group',
                            summarySubHeader: 'Yearly Subscription',
                            summaryBilling: 'Billed yearly',
                            checked: false,
                            quantity: 1,
                        },
                    ],
                },
                {
                    header: 'api reqiest packages',
                    isApi: true,
                    cards: [
                        {
                            subHeader: '100 000',
                            name: 'Api Requests',
                            price: '$ 199.-',
                            priceNumber: 199,
                            requestsNumber: 100000,
                            info: ['$ 0,00199 per request'],
                            summaryHeader: 'Additional API Requests',
                            checked: false,
                            quantity: 1,
                        },
                        {
                            subHeader: '300 000',
                            name: 'Api Requests',
                            price: '$ 499.-',
                            priceNumber: 499,
                            requestsNumber: 300000,
                            info: ['$ 0,00149 per request'],
                            summaryHeader: 'Additional API Requests',
                            checked: false,
                            quantity: 1,
                        },
                        {
                            subHeader: '1 000 000',
                            name: 'Api Requests',
                            price: '$ 999.-',
                            priceNumber: 999,
                            requestsNumber: 1000000,
                            info: ['$ 0,00099 per request'],
                            summaryHeader: 'Additional API Requests',
                            checked: false,
                            quantity: 1,
                        },
                    ],
                },
            ],
            fiatAssetSymbols: ['USD', 'EUR'],
            activeFiatAssetIndex: 0,
            quotationRate: 1,
            agreeTermsAndConditions: false,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        availableBalance() {
            return this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.fiatAssetSymbols[this.activeFiatAssetIndex]
                && b.placementName === 'Single Broker'
                && b.accountId === this.$store.getters['Accounts/getAccountByName']('main').id)?.free ?? 0;
        },
        summaryCards() {
            const result: Card[] = [];
            this.packages.forEach((p) => {
                p.cards.forEach((c) => {
                    if (c.checked) {
                        result.push(c);
                    }
                });
            });
            return result;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        totalPrice() {
            return this.summaryCards.reduce((accum, current) => {
                return accum + (current.priceNumber * current.quantity);
            }, 0);
        },
        isAnyPackagesSelected() {
            return this.packages.some((p) => {
                return p.cards.some((c) => c.checked);
            });
        },
    },
    methods: {
        toggleCardChecked(packageIndex, cardIndex) {
            const tempCard = this.packages[packageIndex].cards[cardIndex];
            const tempCardsArray = this.packages[packageIndex].cards;
            this.packages[packageIndex].cards = tempCardsArray.map((card) => ({ ...card, checked: false, quantity: 1 }));
            this.packages[packageIndex].cards.splice(cardIndex, 1, {
                ...tempCard,
                checked: !tempCard.checked,
                quantity: 1,
            });
        },
        incrementCardQuantity(packageIndex, cardIndex) {
            const tempCard = this.packages[packageIndex].cards[cardIndex];
            this.packages[packageIndex].cards.splice(cardIndex, 1, {
                ...tempCard,
                quantity: tempCard.quantity === 99 ? 99 : tempCard.quantity + 1,
            });
        },
        decrementCardQuantity(packageIndex, cardIndex) {
            const tempCard = this.packages[packageIndex].cards[cardIndex];
            this.packages[packageIndex].cards.splice(cardIndex, 1, {
                ...tempCard,
                quantity: tempCard.quantity === 1 ? 1 : tempCard.quantity - 1,
            });
        },
        getCardCount(card) {
            if (card.requestsNumber) {
                return card.requestsNumber * card.quantity;
            }
            return card.quantity;
        },
        getCardSummaryPrice(card) {
            return card.priceNumber * card.quantity;
        },
    },
    async mounted() {
        const { data: { rate } } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest({
            quotationSymbol: this.quotationAssetSymbol,
            symbol: 'USD',
        }));
        this.quotationRate = rate;
    },
});
